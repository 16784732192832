import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import withSizes from 'react-sizes'
import { BREAKPOINT_XS_MOBILE } from '../../constants/CatalogUtils'

import {
  CalculatorOutlined,
  ShoppingCartOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import {
  Input,
  Carousel,
  Col,
  Row,
  Tooltip,
  Table,
  Tag,
  Form,
  Image,
  Divider,
  Typography,
  Button,
} from 'antd'
import DisplayIVA from '../common/DisplayIVA'
import DisplayStock from '../common/DisplayStock'
import DisplayAmount from '../common/DisplayAmount'
import ReactResizeDetector from 'react-resize-detector'
import { getImagesPath, getProductImagePath, chunkArray } from '../../lib/CatalogUtils'
import {
  addItemToOrder,
  applyDiscountsToProducts,
  getProductPriceByClient,
  addItemToQuotation,
  getApplicationByProductCode,
  showDealModal,
} from '../../actions'
import { getRolename } from '../../lib/AuthUtils'
import moment from 'moment-business-days'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { getIvaToCalc } from '../../lib/IVAUtils'
import PRODUCT_CONSTANTS from '../../constants/ProductConstants'
import getConfig from 'next/config'
import Favorite from '../common/Favorite'
import { trackAnalyticsCommerceEvent } from '../../Utils/AnalyticsUtils'
const { publicRuntimeConfig } = getConfig()
const { TextArea } = Input
const { Paragraph } = Typography

let inc = 0

class CatalogItemDetail extends Component {
  // CustomLabel for Google Analytics events triggered from this component
  GA_LABEL = 'Producto - detalle en modal'

  constructor(props) {
    super(props)
    const {
      id,
      codigodearticulo,
      descripcion,
      preciolista,
      tipodeiva,
      cantidadminima,
      multiplo,
      parent,
      data_cart,
      semaforo,
      semaforo_1000,
      semaforo_1100,
      semaforo_1200,
    } = this.props.item
    let cantidad = 1
    let observaciones = ''

    if (cantidadminima && multiplo && parent !== 'cart') {
      cantidad = cantidadminima > multiplo ? cantidadminima : multiplo
    } else if (cantidadminima && parent !== 'cart') {
      cantidad = cantidadminima
    } else if (cantidadminima && parent === 'cart' && data_cart) {
      cantidad = data_cart.cantidad
      observaciones = data_cart.observaciones
    }

    // TODO hbobadilla:  Solo enviar al pai lo que realmente se necesita para esta funcionalidad.
    this.itemToAdd = {
      id,
      codigodearticulo,
      descripcion,
      precio: preciolista,
      cantidad,
      cantidadminima,
      multiplo,
      tipodeiva,
      observaciones,
      semaforo,
      semaforo_1000,
      semaforo_1100,
      semaforo_1200,
    }

    this.state = {
      quantity: cantidad,
      observaciones,
      previewVisible: false,
      previewImage: '',
      showPrices: true,
      expanded: false,
    }
  }

  toggleExpand = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }))
  }

  componentDidMount() {
    const { current, item, roleName } = this.props

    if (roleName == 'cliente') {
      this.props.getProductPriceByClient({ codigodearticulo: item.codigodearticulo })
    }

    if (current && current.cliente && current.cliente.codigocliente) {
      this.props.applyDiscountsToProducts({
        clientCode: current.cliente.codigocliente,
        products: [{ codigodearticulo: item.codigodearticulo, cantidad: 1 }],
      })
    }

    if (item.codigodearticulo) {
      this.props.getApplicationByProductCode({ codigodearticulo: item.codigodearticulo })
    }

    const closeButton = document.getElementsByClassName('ant-modal-close')[0]
    if (closeButton) {
      closeButton.tabIndex = 6
    }
  }

  openDealModal = (record, action) => {
    this.props.onCancel()
    const payload = { showModal: true, dealItem: { ...record } }
    this.props.showDealModal(payload)
  }

  onInputChange = (item, value) => {
    if (item === 'cantidad') {
      this.itemToAdd[item] = parseInt(value)
    } else {
      this.itemToAdd[item] = value
    }
  }

  trackEvent = (action, item, user, analyticsEventParams) => {
    trackAnalyticsCommerceEvent(
      action,
      this.GA_LABEL,
      item && item.codigodearticulo ? item.codigodearticulo : null,
      user,
      item && item.preciolista ? item.preciolista : null,
      analyticsEventParams,
    )
  }

  onOk = (action) => {
    const { addItemToOrder, addItemToQuotation, onOk, user } = this.props
    const { parent } = this.props.item

    const { codigodearticulo, descripcion, precio, cantidad } = this.itemToAdd

    const analyticsEventParams = [
      {
        item_id: codigodearticulo,
        item_name: descripcion,
        price: precio,
        quantity: cantidad,
      },
    ]

    const handleAction = (trackAction, addItemAction, itemToAdd) => {
      this.trackEvent(trackAction, this.itemToAdd, user, analyticsEventParams)
      addItemAction({ item: { parent, ...this.itemToAdd } })
      onOk()
    }

    const actionMap = {
      cart: () => handleAction('add_to_cart', addItemToOrder),
      estimate: () => handleAction('add_to_quoter', addItemToQuotation),
    }

    if (actionMap[action]) {
      actionMap[action]()
    }
  }

  checkDealPrice(product) {
    let result = null
    if (product.preciooferta) {
      const today = moment()
      if (
        product.oferta_fechafin &&
        moment(product.oferta_fechafin).isAfter(today) &&
        product.oferta_fechainicio &&
        moment(product.oferta_fechainicio).isBefore(today)
      ) {
        result = product.preciooferta
      }
    }
    return result
  }

  renderExtendedRow = (record, names, extendedData) => {
    const data = extendedData[record.key]
    return (
      <Col style={{}}>
        {names.length > 0 && Object.keys(data).length > 0 ? (
          chunkArray(names, 2).map((row, rowIndex) => {
            return (
              <Row key={rowIndex}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {row.map((col, colIndex) => {
                    const value = data[col.key]
                    return (
                      <Col
                        key={`${rowIndex}${colIndex}`}
                        style={{ minWidth: '30%', maxWidth: '50%' }}
                      >
                        <span style={{ fontWeight: 'bold', marginRight: 10 }}>{col.title}: </span>
                        <span>{value && value != '' ? value : '-'}</span>
                      </Col>
                    )
                  })}
                </div>
              </Row>
            )
          })
        ) : (
          <Row>
            <dev style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <span>{this.props.t('No extra information')}</span>
            </dev>
          </Row>
        )}
      </Col>
    )
  }

  renderCaracteristicas = () => {
    const { listOfApplications, isMobile } = this.props

    return listOfApplications.map((categoria, kc) => {
      const applications = categoria.aplicaciones
      const schema = categoria.schema
      const excludeMap = {}
  
      // Build an array of items
      const columns = []
      const extendedColumns = []
  
      // Build the table columns
      schema.valores.forEach((col) => {
        if (col.grupo === PRODUCT_CONSTANTS.GROUPS.MAIN) {
          columns.push({
            title: col.nombre,
            dataIndex: col.codigo,
            key: col.codigo,
            width: 100,
            align: 'top',
            render: (text) => {
              if (!text) {
                return <div>-</div>
              }
              return <div>{text}</div>
            },
          })
        } else {
          excludeMap[col.codigo] = true
          extendedColumns.push({ key: col.codigo, title: col.nombre })
        }
      })
  
      const data = []
      const extendedData = []
  
      applications.forEach((col, index) => {
        const row = { key: index }
        const extendedRow = {}
  
        Object.keys(col.caracteristicas).forEach((property) => {
          if (excludeMap[property]) {
            extendedRow[property] = col.caracteristicas[property]
          } else {
            row[property] = col.caracteristicas[property]
          }
        })
        data.push(row)
        extendedData.push(extendedRow)
      })
  
      return (
        <Col key={kc} style={{ textAlign: 'center', marginTop: '15px' }}>
          <Table
            scroll={isMobile ? { x: 'max-content' } : undefined}
            columns={columns}
            expandedRowRender={(record) =>
              this.renderExtendedRow(record, extendedColumns, extendedData)
            }
            dataSource={data}
            pagination={true}
          />
        </Col>
      )
    })
  }

  calcPriceVAT(price, iva) {
    const vat = getIvaToCalc(iva)
    if (vat) {
      return price * vat
    }
    return price
  }

  renderDiscounts = (discounts) => {
    if (!Array.isArray(discounts) || discounts.length === 0) {
      return ''
    }

    const result = discounts.map((discount) => discount.replace(/(\.\d{2})\d*/, '$1')).join(', ')

    return result
  }

  handleImagePreview = (image) => {
    this.setState({
      previewImage: image,
      previewVisible: true,
    })
  }

  showPrices = () => {
    this.setState({ showPrices: !this.state.showPrices })
  }

  render() {
    const {
      t,
      item,
      roleName,
      productPrice,
      itemDetail,
      showFavoriteButton = true,
      isDeleted = false,
    } = this.props
    const discounts =
      itemDetail && itemDetail.discount && itemDetail.discount.descuento
        ? itemDetail.discount.descuento
        : null
    const dealPrice = this.checkDealPrice(item)
    const costPrice =
      itemDetail && itemDetail.discount && itemDetail.discount.precioFinal
        ? itemDetail.discount.precioFinal
        : item.preciolista
    const cantidad = item.cantidadminima ? item.cantidadminima : 1
    const increment = item.multiplo ? item.multiplo : 1
    const initValues = {
      cantidad: this.state.quantity,
      observaciones: this.state.observaciones,
    }

    const { showPrices, expanded } = this.state
    const descripcionadicional = item.descripcionadicional
    const descripcioncatalogo = item.descripcioncatalogo
    const length = 300

    // Add VAT to suggested price if not calculated
    let suggestedPrice
    if (productPrice) {
      suggestedPrice = this.calcPriceVAT(productPrice.data, item.tipodeiva)
    } else {
      suggestedPrice = this.calcPriceVAT(item.preciolista, item.tipodeiva)
    }

    return (
      <ReactResizeDetector handleWidth handleHeight>
        {() => (
          <div className='catalog-item-detail'>
            <div className='catalog-item-detail-header red-gradient'>
              <div>
                <span className='title'>
                  {item.codigodearticulo}{' '}
                  {!showPrices ? (
                    <Tooltip title={t('Normal prices view')}>
                      <EyeInvisibleOutlined
                        onClick={this.showPrices}
                        style={{ color: 'white', marginLeft: '3px' }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('Customer price view')}>
                      <EyeOutlined
                        onClick={this.showPrices}
                        style={{ color: 'white', marginLeft: '3px' }}
                      />
                    </Tooltip>
                  )}
                </span>
              </div>
              <div className='icons-container'>
                {!isDeleted && roleName != 'admin' && (
                  <div>
                    {roleName == 'cliente' && (
                      <Tooltip title={t('Add products to quotation')}>
                        <a
                          tabIndex={5}
                          onClick={() => this.onOk('estimate')}
                          type={'primary'}
                          disabled={false}
                        >
                          <CalculatorOutlined
                            className='icon-card-item'
                            // TODO: Check how to disable this button on quantity error
                            // className={(this.state.errors) ? "icon-card-item " : "icon-card-item color-white"}
                          />
                        </a>
                      </Tooltip>
                    )}
                    {(roleName == 'cliente' || roleName == 'vendedor') && showFavoriteButton && (
                      <Favorite tabIndex={4} product={item} GA_LABEL={this.GA_LABEL} />
                    )}
                  </div>
                )}
                <Tooltip title={t('Close')}>
                  <a
                    tabIndex={6}
                    onClick={() => {
                      this.props.onCancel()
                    }}
                    type={'primary'}
                    disabled={false}
                  >
                    <CloseCircleOutlined className='icon-card-item' />
                  </a>
                </Tooltip>
              </div>
            </div>
            <div className='product-modal-description-container'>
              <div className='text-header-model-product'>
                <Paragraph
                  ellipsis={
                    expanded
                      ? false
                      : {
                          rows: 2,
                          expandable: true,
                          onExpand: this.toggleExpand,
                          symbol: <span style={{ fontWeight: 'bold' }}>{t('readMore')}</span>,
                        }
                  }
                >
                  {expanded || !(descripcioncatalogo.length > length)
                    ? descripcioncatalogo
                    : `${descripcioncatalogo.substring(0, length)}...`}
                </Paragraph>
              </div>
              <div className='product-modal-secondary-data'>
                <div className='catalog-item-card-stock'>
                  <div>{t('Stock')}</div>
                  <DisplayStock product={item} />
                </div>
                <div className='product-modal-price-iva'>
                  <div>{t('VAT')}:</div>
                  <div>
                    <DisplayIVA iva={item.tipodeiva} />
                  </div>
                </div>
              </div>
            </div>
            <div
              className='catalog-item-detail-info-wrapper'
              style={{ flexDirection: window.innerHeight >= window.innerWidth ? 'column' : 'row' }}
            >
              <div style={{ flex: 2 }}>
                <div className='catalog-item-detail-pictures'>
                  <Carousel autoplay>
                    {item.imagesFiles && item.imagesFiles.length > 0 ? (
                      item.imagesFiles.map((image, i) => (
                        <div key={i}>
                          <Image
                            src={getImagesPath() + image}
                            className='catalog-item-detail-picture'
                            preview={{
                              visible: false,
                              onVisibleChange: (vis) => this.setState({ previewVisible: vis }),
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <div key={0}>
                        <Image
                          src={getProductImagePath(item)}
                          className='catalog-item-detail-picture'
                        />
                      </div>
                    )}
                  </Carousel>
                  {/* Preview for images */}
                  <Image.PreviewGroup
                    preview={{
                      visible: this.state.previewVisible,
                      onVisibleChange: (vis) => this.setState({ previewVisible: vis }),
                    }}
                  >
                    {item.imagesFiles.map((image, i) => (
                      <Image key={i} src={getImagesPath() + image} hidden />
                    ))}
                  </Image.PreviewGroup>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div className='catalog-item-detail-info'>
                  <div
                    style={{
                      height:
                        !dealPrice && !suggestedPrice && window.innerWidth > window.innerHeight
                          ? 150
                          : 'auto',
                    }}
                  >
                    <div className='catalog-item-detail-info-prices'>
                      <div className='product-modal-price-container ml-0'>
                        {showPrices && (
                          <div>
                            <div className='price-label'>{t('Cost_s/IVA')}:</div>
                            <span className='price-value'>
                              <DisplayAmount
                                amount={costPrice}
                                currency={item.moneda_preciolista}
                                position={'flex-start'}
                              />
                            </span>
                          </div>
                        )}
                        {showPrices && (
                          <div>
                            <div style={{ fontSize: '11px' }}>
                              {' '}
                              {t('discountsToShow')} {this.renderDiscounts(discounts)}{' '}
                            </div>
                          </div>
                        )}
                      </div>
                      {showPrices && (
                        <div className='product-modal-price-container ml-0'>
                          <div>
                            <div className='price-label'>{t('listPrice')}: </div>
                            <span className='price-value'>
                              <DisplayAmount
                                amount={item.preciolista}
                                currency={item.moneda_preciolista}
                                position={'flex-start'}
                              />
                            </span>
                          </div>
                        </div>
                      )}
                      {dealPrice && showPrices && (
                        <div className='product-modal-price-container ml-0 bg-deal-price'>
                          <div>
                            <div className='price-label'>{t('dealPrice')}:</div>
                            <span className='price-value'>
                              <DisplayAmount
                                amount={dealPrice}
                                currency={item.moneda_preciooferta}
                                position={'flex-start'}
                              />
                            </span>
                          </div>
                        </div>
                      )}
                      {roleName == 'cliente' && (
                        <div className='product-modal-price-container'>
                          <div>
                            <div className='price-label'>{t('suggestedPrice')}:</div>
                            <span className='price-value' style={{ fontWeight: 'bold' }}>
                              <DisplayAmount
                                amount={suggestedPrice}
                                currency={item.moneda_preciolista}
                                position={'flex-start'}
                              />
                            </span>
                          </div>
                        </div>
                      )}

                      {itemDetail && itemDetail.showDealLabel && item.isInOffer && (
                        <div className='product-modal-price-container ml-0'>
                          <div>
                            <Tag
                              color='#fe4e37'
                              style={{ borderRadius: '7px', width: '100%', textAlign: 'center' }}
                              onClick={() => this.openDealModal(item.isInOffer)}
                            >
                              <a href='#'>{t('volumeDeal')}</a>
                            </Tag>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Formik
              initialValues={{ ...initValues }}
              onSubmit={() => {}}
              // Check how to build validations according to Role Select - This is way too hard.
              validationSchema={Yup.object().shape({
                cantidad: Yup.number()
                  .required(t('validation-required', { field: t('Quantity') }))
                  .min(cantidad, t('quantity-min', { min: cantidad }))
                  .test('test-name', t('multiple-num', { cant: item.multiplo }), function (value) {
                    if (value % item.multiplo > 0) {
                      return false
                    }
                    return true
                  }),
              })}
              render={({ values, handleBlur, errors, touched, handleSubmit, setFieldValue }) => (
                <div>
                  {!isDeleted && (
                    <Form onSubmit={handleSubmit}>
                      <div className='product-modal-form'>
                        <div className='product-modal-form-observations'>
                          <TextArea
                            className='text-area'
                            placeholder={t('Observations')}
                            tabIndex={3}
                            rows={1}
                            value={values.observaciones}
                            onChange={(event) => {
                              this.onInputChange('observaciones', event.target.value)
                              setFieldValue('observaciones', event.target.value)
                            }}
                            style={{ resize: 'none' }}
                          />
                        </div>
                        <div className='product-modal-form-quantity'>
                          <div className='inputs-and-icon'>
                            <div className='label'>
                              <label>{t('Quantity')}:</label>
                            </div>
                            <div>
                              <Input
                                className='field'
                                autoFocus
                                tabIndex={1}
                                placeholder={t('insert quantity')}
                                id='cantidad'
                                type='number'
                                name='cantidad'
                                step={increment}
                                min={cantidad}
                                value={values.cantidad}
                                onChange={(event) => {
                                  this.onInputChange('cantidad', event.target.value)
                                  setFieldValue('cantidad', event.target.value)
                                }}
                                onBlur={handleBlur}
                                onPressEnter={() => this.onOk('cart')}
                              />
                            </div>
                            <div className='icon-cart'>
                              <Tooltip title={t('Add products to cart')}>
                                <Button
                                  tabIndex={2}
                                  onClick={() => {
                                    this.onOk('cart')
                                  }}
                                  type={'primary'}
                                  disabled={errors.cantidad}
                                  className=''
                                >
                                  <ShoppingCartOutlined
                                    className={
                                      this.state.errors
                                        ? 'icon-card-item'
                                        : 'icon-card-item color-white'
                                    }
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                          <div>
                            {errors.cantidad && touched.cantidad && (
                              <span className='ant-form-explain has-error'>{errors.cantidad}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </div>
              )}
            />
            <Divider className='product-modal-divider' />
            <div className='product-modal-footer'>
              <div className='detail-container'>
                <div style={{ marginBottom: 16 }}>
                  {/* <p className='product-modal-footer-description'>{t('Brands')}</p> */}
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    {item.marcas && item.marcas.length > 0 && item.marcas[0] !== undefined && (
                      <Tag
                        color='red'
                        key={`${inc++}_${item.marcas[0].id}_${item.marcas[0].descripcion}_${new Date().getTime()}`}
                      >
                        {item.marcas[0].descripcion}
                      </Tag>
                    )}
                  </div>
                </div>
                <div>
                  <Paragraph
                    ellipsis={
                      expanded
                        ? false
                        : {
                            rows: 2,
                            expandable: true,
                            onExpand: this.toggleExpand,
                            symbol: <span style={{ fontWeight: 'bold' }}>{t('readMore')}</span>,
                          }
                    }
                    style={{ textAlign: 'start' }}
                  >
                    {expanded || !(descripcionadicional.length > length)
                      ? descripcionadicional
                      : `${descripcionadicional.substring(0, length)}...`}
                  </Paragraph>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }} className='product-modal-characteristics'>
              {this.renderCaracteristicas()}
            </div>
          </div>
        )}
      </ReactResizeDetector>
    )
  }
}

const mapStateToProps = ({ orders, auth, quotation, products, catalog }) => {
  const { current } = orders
  const { productPrice, stock, loadStockSuccess, listOfApplications } = products
  const { user } = auth.user

  const currentQuotation = quotation.current
  const roleName = getRolename(auth)
  const { itemDetail, searchRequest } = catalog
  return {
    current,
    roleName,
    user,
    productPrice,
    currentQuotation,
    itemDetail,
    stock,
    loadStockSuccess,
    listOfApplications,
    filter: searchRequest.filter,
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < BREAKPOINT_XS_MOBILE,
})

export default connect(mapStateToProps, {
  addItemToOrder,
  applyDiscountsToProducts,
  getProductPriceByClient,
  addItemToQuotation,
  getApplicationByProductCode,
  showDealModal,
})(withTranslation()(withSizes(mapSizesToProps)(CatalogItemDetail)))
