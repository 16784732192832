import {
  SUBMIT_RECEIPTS,
  SUBMIT_RECEIPTS_SUCCESS,
  RESET_SUBMIT_RECEIPTS_SUCCESS
} from '../constants/ActionsTypes'


const initialState = {
  isLoadingSubmitReceipts: false,
  submitReceiptsSuccess: false
}

function rootReducer(state = initialState, action) {
  let newState

  switch (action.type) {
    
    case SUBMIT_RECEIPTS:
      newState = { ...state, isLoadingSubmitReceipts: true, submitReceiptsSuccess: false }
      break
    case SUBMIT_RECEIPTS_SUCCESS:
      newState = { ...state, isLoadingSubmitReceipts: false, submitReceiptsSuccess: true }
      break
    case RESET_SUBMIT_RECEIPTS_SUCCESS:
      return {
        ...state,
        submitReceiptsSuccess: false
      }
    default:
      newState = state
  }
  return newState
}

export default rootReducer
