import {
    CLEAR_RECEIPTS_DATA,
    STORE_RECEIPTS_DATA,
    UPLOAD_PROVISIONAL_RECEIPT,
    UPLOAD_PROVISIONAL_RECEIPT_SUCCESS,
    GET_PROVISIONAL_RECEIPTS,
    GET_PROVISIONAL_RECEIPTS_SUCCESS,
    SUBMIT_RECEIPTS,
    SUBMIT_RECEIPTS_SUCCESS,
    RESET_SUBMIT_RECEIPTS_SUCCESS
} from '../constants/ActionsTypes';

export const uploadReceipt = (params) => {
    return {
        type: UPLOAD_PROVISIONAL_RECEIPT,
        payload: params,
    };
};

export const uploadReceiptSuccess = (data) => {
    return {
        type: UPLOAD_PROVISIONAL_RECEIPT_SUCCESS,
        payload: data,
    };
};

export const storeReceiptsData = (receiptsData, operation) => ({
    type: STORE_RECEIPTS_DATA,
    payload: receiptsData,
    operation,
});

export const clearReceiptsData = () => {
    return {
        type: CLEAR_RECEIPTS_DATA,
    };
};

export const getProvisionalReceipts = () => {
    return {
        type: GET_PROVISIONAL_RECEIPTS,
    };
};


export const getProvisionalReceiptsSuccess = (data) => {
    return {
        type: GET_PROVISIONAL_RECEIPTS_SUCCESS,
        payload: data,
    };
};


export const submitReceipts = (params) => {
    return {
        type: SUBMIT_RECEIPTS,
        payload: params,
    };
};

export const submitReceiptsSuccess = (data) => {
    return {
        type: SUBMIT_RECEIPTS_SUCCESS,
        payload: data,
    };
};

export const resetSubmitReceiptsSuccess = () => ({
    type: RESET_SUBMIT_RECEIPTS_SUCCESS
  });
