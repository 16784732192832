import Axios from 'axios';

export const getCurrencyTypes = async () => {
    const response = await Axios.get('/currencies/listCurrencyTypes');
    return response;
};

export const uploadReceipt = async (params) => {
    const response = await Axios.post('/provisionalReceipts/uploadProvisionalReceipts', params);
    return response;
};

export const submitReceipts = async (params) => {
    const response = await Axios.post('/billingDetails/submitReceipts', params);
    return response;
};

export const getBanks = async () => {
    const response = await Axios.get('/banks/listBanks');
    return response;
};

export const getTaxWithholdingsTypes = async () => {
    const response = await Axios.get('/taxWithholdings/listTaxWithholdingsTypes');
    return response;
};

export const getProvisionalReceipts = async () => {
    const response = await Axios.get('/provisionalReceipts/getProvisionalReceipts');
    return response;
};