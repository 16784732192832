import {
  DOWNLOAD_PRICES_LIST,
  SET_SHOW_PRICES_LIST_MODAL,
  DOWNLOAD_PRICES_LIST_SUCCESS,
  DOWNLOAD_PRICES_LIST_ERROR,
  SET_SELECTED_CATALOG_LINE,
  SET_SHOW_CATEGORIES,
  SET_SHOW_QUICK_CART,
  SET_SHOW_ESTIMATE,
  GET_BRANDS_BY_SEGMENTS,
  GET_PRODUCTS_BY_LINEA_CATALOGO,
  SEARCH_CATALOG_PRODUCTS,
  SHOW_ITEM_DETAIL,
  HIDE_ITEM_DETAIL,
  SET_CATALOG_SEGMENT,
  SET_FILTER_SELECTED,
  GET_LINEAS_CATALOGOS,
  GET_LINEAS_CATALOGOS_SUCCESS,
  GET_PRODUCTS_BY_VENDOR_ID,
  GET_ONLINE_ORDER_TO_MERGE,
  GET_ONLINE_ORDER_TO_MERGE_SUCCESS,
  GET_ONLINE_QUOTATION_TO_MERGE,
  GET_ONLINE_QUOTATION_TO_MERGE_SUCCESS, CLEAR_SEARCH_PRODUCT,
  GET_NEWS_FOR_FAVORITES,
  GET_NEWS_FOR_FAVORITES_SUCCESS,
  TOGGLE_FILTER_BY_STOCK,
  TOGGLE_SHOW_BADGE
} from '../constants/ActionsTypes';

export const downloadPricesList = (filter, format) => {
  return {
    type: DOWNLOAD_PRICES_LIST,
    payload: { filter, format }
  }
};

export const toggleFilterByStock = () => ({
    type: TOGGLE_FILTER_BY_STOCK,
  });

export const setShowPricesListModal = () => {
  return {
    type: SET_SHOW_PRICES_LIST_MODAL,
  }
};

export const downloadPricesListSuccess = () => {
  return {
    type: DOWNLOAD_PRICES_LIST_SUCCESS,
  }
};

export const downloadPricesListError = response => {
  return {
    type: DOWNLOAD_PRICES_LIST_ERROR,
    payload: response
  }
};

export const setShowCategories = (data) => {
    return {
        type: SET_SHOW_CATEGORIES,
        payload: data,
    };
};

export const setSelectedCatalogLine = (data) => {
    return {
        type: SET_SELECTED_CATALOG_LINE,
        payload: data,
    };
};

export const setShowQuickCart = (data) => {
    return {
        type: SET_SHOW_QUICK_CART,
        payload: data,
    };
};

export const setShowEstimate = (data) => {
    return {
        type: SET_SHOW_ESTIMATE,
        payload: data,
    };
};

export const getBrandsBySegments = (segmentIds) => {
    return {
        type: GET_BRANDS_BY_SEGMENTS,
        payload: { segmentIds },
    };
};

export const getProductsByLineaCatalogo = (data) => {
    return {
        type: GET_PRODUCTS_BY_LINEA_CATALOGO,
        payload: { ...data },
    };
};

export const getProductsByVendorId = (data) => {
    return {
        type: GET_PRODUCTS_BY_VENDOR_ID,
        payload: { ...data },
    };
};

export const searchCatalogProducts = (data) => {
    return {
        type: SEARCH_CATALOG_PRODUCTS,
        payload: {
            ...data,
        },
    };
};

export const showItemDetail = (data) => {
    return {
        type: SHOW_ITEM_DETAIL,
        payload: data,
    };
};

export const hideItemDetail = (data) => {
    return {
        type: HIDE_ITEM_DETAIL,
    };
};

export const setCatalogSegment = (segment) => {
    return {
        type: SET_CATALOG_SEGMENT,
        payload: segment,
    };
};

export const setFilterSelected = (filter) => {
    return {
        type: SET_FILTER_SELECTED,
        payload: filter,
    };
};

export const getLineasCatalogos = () => {
    return {
        type: GET_LINEAS_CATALOGOS,
    };
};

export const getLineasCatalogosSuccess = (lineas) => {
    return {
        type: GET_LINEAS_CATALOGOS_SUCCESS,
        payload: lineas,
    };
};

export const getOnlineOrderToMerge = (id) => {
    return {
        type: GET_ONLINE_ORDER_TO_MERGE,
        payload: id,
    };
};

export const getOnlineOrderToMergeSuccess = (order) => {
    return {
        type: GET_ONLINE_ORDER_TO_MERGE_SUCCESS,
        payload: order,
    };
};

export const getOnlineQuotationToMerge = (id) => {
    return {
        type: GET_ONLINE_QUOTATION_TO_MERGE,
        payload: id,
    };
};

export const getOnlineQuotationToMergeSuccess = (quotation) => {
    return {
        type: GET_ONLINE_QUOTATION_TO_MERGE_SUCCESS,
        payload: quotation,
    };
};

export const clearSearchProduct = () => {
    return {
        type: CLEAR_SEARCH_PRODUCT,
    };
};

export const getNewsForFavorites = (params) => {
    return {
        type: GET_NEWS_FOR_FAVORITES,
        payload: params,
    };
};

export const getNewsForFavoritesSuccess = (data) => {
    return {
        type: GET_NEWS_FOR_FAVORITES_SUCCESS,
        payload: data,
    };
};

export const toggleShowBadge = () => {
    return {
        type: TOGGLE_SHOW_BADGE,
    };
};
