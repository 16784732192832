import {
    GET_ACCOUNT_FORM,
    GET_ACCOUNT_FORM_SUCCESS,
    GET_ACCOUNT_FORM_ERROR,
    SEARCH_ACCOUNT_FORM,
    SEARCH_ACCOUNT_FORM_SUCCESS,
    SEARCH_ACCOUNT_FORM_ERROR,
    CREATE_ACCOUNT_FORM,
    CREATE_ACCOUNT_FORM_SUCCESS,
    CREATE_ACCOUNT_FORM_ERROR,
    EDIT_ACCOUNT_FORM,
    EDIT_ACCOUNT_FORM_SUCCESS,
    EDIT_ACCOUNT_FORM_ERROR,
    DELETE_ACCOUNT_FORM,
    DELETE_ACCOUNT_FORM_SUCCESS,
    DELETE_ACCOUNT_FORM_ERROR,
    COMPLETE_ACCOUNT_FORM,
    COMPLETE_ACCOUNT_FORM_SUCCESS,
    COMPLETE_ACCOUNT_FORM_ERROR,
    GET_WORKS,
    GET_WORKS_SUCCESS,
    GET_WORKS_ERROR,
    GET_SEGMENTS_WITH_BRANDS,
    GET_SEGMENTS_WITH_BRANDS_SUCCESS,
    GET_SEGMENTS_WITH_BRANDS_ERROR,
    CLEAR_ACCOUNT_FORM_STATE,
    GET_LINK_FOR_DETAIL,
    GET_LINK_FOR_DETAIL_SUCCESS,
    GET_LINK_FOR_DETAIL_ERROR, CLEAR_LINK_STATE
} from '../constants/ActionsTypes';

const initialState = {
    creating: undefined,
    editing: undefined,
    deleting: undefined,
    completed: undefined,
    element: undefined,
    savedElement: undefined,
    isGetElementError: undefined,
    data: undefined,
    works: undefined,
    segments: undefined,
    linkForDetail: undefined,
    isGetLinkSuccess: false,
    errorOnCompleteCreateAccount: null
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ACCOUNT_FORM:
            return { ...state, element: null, isGetElementError: false };
        case GET_ACCOUNT_FORM_ERROR:
            return { ...state, isGetElementError: true };
        case GET_ACCOUNT_FORM_SUCCESS:
            return { ...state, element: action.payload };

        case SEARCH_ACCOUNT_FORM_ERROR:
            return { ...state };
        case SEARCH_ACCOUNT_FORM_SUCCESS:
            return { ...state, data: action.payload };

        case CREATE_ACCOUNT_FORM:
            return { ...state, creating: false};
        case CREATE_ACCOUNT_FORM_SUCCESS:
            return { ...state, creating: true, savedElement: action.payload };
        case CREATE_ACCOUNT_FORM_ERROR:
            return { ...state, creating: false };

        case EDIT_ACCOUNT_FORM:
            return { ...state, editing: false};
        case EDIT_ACCOUNT_FORM_SUCCESS:
            return { ...state, editing: true, savedElement: action.payload };
        case EDIT_ACCOUNT_FORM_ERROR:
            return { ...state, editing: false };

        case DELETE_ACCOUNT_FORM:
            return { ...state, deleting: false};
        case DELETE_ACCOUNT_FORM_SUCCESS:
            return { ...state, deleting: true };
        case DELETE_ACCOUNT_FORM_ERROR:
            return { ...state, deleting: false };

        case COMPLETE_ACCOUNT_FORM:
            return { ...state, completed: false };
        case COMPLETE_ACCOUNT_FORM_SUCCESS:
            return { ...state, completed: true };
        case COMPLETE_ACCOUNT_FORM_ERROR:
            return { ...state, completed: false, errorOnCompleteCreateAccount: true };

        case GET_WORKS_SUCCESS:
            return { ...state, works: action.payload };

        case GET_SEGMENTS_WITH_BRANDS_SUCCESS:
            return { ...state, segments: action.payload };

        case GET_LINK_FOR_DETAIL:
            return { ...state, isGetLinkSuccess: false }
        case GET_LINK_FOR_DETAIL_SUCCESS:
            return { ...state, isGetLinkSuccess: true, linkForDetail: action.payload }
        case GET_LINK_FOR_DETAIL_ERROR:
            return { ...state, isGetLinkSuccess: false, linkForDetail: undefined }

        case CLEAR_ACCOUNT_FORM_STATE:
            return { ...initialState }

        case CLEAR_LINK_STATE:
            return { ...state, linkForDetail: undefined, isGetLinkSuccess: false }

        default:
            return state
    }
}

export default rootReducer
