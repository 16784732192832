import { combineReducers } from 'redux';
import Auth from './Auth';
import Common from './Common';
import BillingDetail from './BillingDetail';
import Users from './Users';
import Clients from './Clients';
import Sellers from './Sellers';
import ProvisionalReceipts from './ProvisionalReceipts';
import Branches from './Branches';
import Transports from './Transports';
import MainGroups from './MainGroups';
import Groups from './Groups';
import SubGroups from './SubGroups';
import Segments from './Segments';
import Brands from './Brands';
import CatalogEntries from './CatalogEntries';
import Products from './Products';
import Deals from './Deals';
import Catalog from './Catalog';
import CurrentAccount from './CurrentAccount';
import Statistics from './Statistics';
import Orders from './Orders';
import ProductImages from './ProductImages';
import ConfigureClient from './ConfigureClient';
import Quotation from './Quotation';
import Banners from './Banners';
import { SIGNOUT_USER } from '../constants/ActionsTypes'
import Categories from './Categories';
import LockMark from './LockMark';
import Connectivity from './Connectivity';
import Appointment from './Appointment';
import Dashboard from './Dashboard';
import Applications from './Applications';
import Characteristics from './Characteristics';
import AccountForms from "./AccountForm";
import UserConfig from './UserConfig';
import Persons from './Persons';

const reducers = combineReducers({
  common: Common,
  auth: Auth,
  billingDetail: BillingDetail,
  users: Users,
  branches: Branches,
  transports: Transports,
  clients: Clients,
  sellers: Sellers,
  provisionalReceipts: ProvisionalReceipts,
  mainGroups: MainGroups,
  groups: Groups,
  subGroups: SubGroups,
  segments: Segments,
  brands: Brands,
  catalogEntries: CatalogEntries,
  products: Products,
  deals: Deals,
  currentAccount: CurrentAccount,
  statistics: Statistics,
  catalog: Catalog,
  orders: Orders,
  productImages: ProductImages,
  configureClient:ConfigureClient,
  quotation:Quotation,
  banners:Banners,
  categories:Categories,
  lockMark: LockMark,
  connectivity: Connectivity,
  appointment: Appointment,
  dashboard: Dashboard,
  accountForms: AccountForms,
  userConfig: UserConfig,
  applications: Applications,
  characteristics: Characteristics,
  persons: Persons
});

const rootReducer = (state, action) => {
  if (action.type === SIGNOUT_USER) {
    state = undefined
  }

  return reducers(state, action);
}

export default rootReducer;
