import Axios from 'axios';

export const getCatalogEntry = async (catalogId) => {
    const response = await Axios.get(
        '/lineasCatalogos/' +
            catalogId +
            '?filter={"include":[ {"relation": "segmentos"},{ "relation":  "lineas", "scope": { "include": "sublinea"}},{"relation":  "marca"}]}'
    );
    return response;
};

export const searchCatalogEntries = async (searchParams) => {
    const response = await Axios.post('/lineasCatalogos/search', searchParams);
    return response.data;
};

export const addCatalogEntry = async (data) => {
    const response = await Axios.put('/lineasCatalogos', data);
    return response.data;
};

export const addRemoveCatalogSegmentsEntry = async (data) => {
    const response = await Axios.post('/lineasCatalogosSegmentos/addRemoveSegments', data);
    return response.data;
};

export const addRemoveCatalogLinesEntry = async (data) => {
    const response = await Axios.post('/lineasCatalogosLineas/addRemoveLines', data);
    return response.data;
};

export const getProductsByCatalogLineRequest = async (data) => {
    const response = await Axios.post('/lineasCatalogos/getProductsByCatalogLine', data);
    return response.data;
};

export const getProductsByVendorIdRequest = async (data) => {
    const response = await Axios.post('/lineasCatalogos/getProductsByVendorId', {
        marcaId: data.marcaId,
        page: data.page,
        pageSize: data.pageSize,
        order: data.order,
    });
    return response.data;
};
