//Auth
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';

//User
export const GET_USER = 'get_user';
export const GET_USER_SUCCESS = 'get_user_success';
export const GET_USER_ERROR = 'get_user_error';
export const CREATE_USER = 'create_user';
export const CREATE_USER_SUCCESS = 'create_user_success';
export const CREATE_USER_ERROR = 'create_user_error';
export const UPDATE_USER = 'update_user';
export const UPDATE_USER_SUCCESS = 'update_user_success';
export const UPDATE_USER_ERROR = 'update_user_error';
export const DELETE_USER = 'delete_user';
export const DELETE_USER_SUCCESS = 'delete_user_success';
export const DELETE_USER_ERROR = 'delete_user_error';
export const CHANGE_PASSWORD = 'change_password';
export const CHANGE_PASSWORD_SUCCESS = 'change_password_success';
export const UPDATE_EMAIL = 'update_password';
export const UPDATE_EMAIL_SUCCESS = 'update_password_success';
export const SEND_PASSWORD_REQUEST_EMAIL = 'send_password_request_email';
export const SEND_PASSWORD_REQUEST_EMAIL_SUCCESS = 'send_password_request_email_success';
export const SEND_PASSWORD_REQUEST_EMAIL_ERROR = 'send_password_request_email_error';
export const INIT_SEND_PASSWORD_REQUEST_EMAIL = 'init_password_request_email';
export const SET_PASSWORD = 'set_password';
export const SET_PASSWORD_SUCCESS = 'set_password_success';
export const SET_PASSWORD_ERROR = 'set_password_error';
export const INIT_SET_PASSWORD = 'init_set_password';
export const VERIFY_TOKEN = 'verify_token';
export const VERIFY_TOKEN_SUCCESS= 'verify_token_success';
export const VERIFY_TOKEN_ERROR = 'verify_token_error';

//Users
export const GET_USERS = 'get_users';
export const GET_USERS_SUCCESS = 'get_users_success';
export const GET_USERS_ERROR = 'get_users_error';
export const SEARCH_USERS = 'search_users';
export const SEARCH_USERS_SUCCESS = 'search_users_success';
export const SEARCH_USERS_ERROR = 'search_users_error';
export const AUTOCOMPLETE_USERS_SUCCESS = 'search_users_success';
export const AUTOCOMPLETE_USERS_ERROR = 'search_users_error';
export const GET_BRANDS_BY_USER = 'get_brands_by_user';
export const GET_BRANDS_BY_USER_SUCCESS = 'get_brands_by_user_success';
export const GET_ALL_BRANDS = 'get_all_brands';
export const GET_ALL_BRANDS_SUCCESS = 'get_all_brands_success';
export const GET_BRANDS_BY_USER_ERROR = 'get_brands_by_user_error';


//Branches
export const GET_BRANCHES = 'get_branchs';
export const GET_BRANCHES_SUCCESS = 'get_branchs_success';
export const GET_BRANCHES_ERROR = 'get_branchs_error';
export const GET_BRANCH = 'get_branch';
export const GET_BRANCH_ERROR = 'get_branch_error';
export const GET_BRANCH_SUCCESS = 'get_branch_success';
export const SEARCH_BRANCHES = 'search_branches';
export const SEARCH_BRANCHES_SUCCESS = 'search_branches_success';
export const AUTOCOMPLETE_BRANCHES = 'autocomplete_branches';
export const AUTOCOMPLETE_BRANCHES_SUCCESS = 'autocomplete_branches_success';
export const EDIT_CONTACT_LINES = 'edit_contact_lines';
export const EDIT_CONTACT_LINES_SUCCESS = 'edit_contact_lines_success';


//Transports
export const GET_TRANSPORTS = 'get_transports';
export const GET_TRANSPORTS_SUCCESS = 'get_transports_success';
export const GET_TRANSPORTS_ERROR = 'get_transports_error';
export const GET_TRANSPORT = 'get_transport';
export const GET_TRANSPORT_ERROR = 'get_transport_error';
export const GET_TRANSPORT_SUCCESS = 'get_transport_success';
export const SEARCH_TRANSPORTS = 'search_transports';
export const SEARCH_TRANSPORTS_SUCCESS = 'search_transports_success';
export const AUTOCOMPLETE_TRANSPORTS = 'autocomplete_transports';
export const AUTOCOMPLETE_TRANSPORTS_SUCCESS = 'autocomplete_transports_success';


//Clients
export const GET_CLIENTS = 'get_clients';
export const GET_CLIENTS_SUCCESS = 'get_clients_success';
export const GET_CLIENTS_ERROR = 'get_clients_error';
export const GET_CLIENT = 'get_client';
export const GET_CLIENT_SUCCESS = 'get_client_success';
export const GET_CLIENT_ERROR = 'get_client_error';
export const SEARCH_CLIENTS = 'search_clients';
export const SEARCH_CLIENTS_SUCCESS = 'search_client_success';
export const AUTOCOMPLETE_CLIENTS = 'autocomple_clients';
export const AUTOCOMPLETE_CLIENTS_SUCCESS = 'autocomple_clients_success';
export const AUTOCOMPLETE_MY_CLIENTS = 'autocomple_my_clients';
export const AUTOCOMPLETE_MY_CLIENTS_SUCCESS = 'autocomple_my_clients_success';
export const CLEAR_AUTOCOMPLETE_CLIENTS = 'clear_autocomplete_clients';

//Receipts
export const STORE_RECEIPTS_DATA = 'store_receipts_data';
export const CLEAR_RECEIPTS_DATA = 'clear_receipts_data';

//Sellers
export const GET_SELLERS = 'get_sellers';
export const GET_SELLERS_SUCCESS = 'get_sellers_success';
export const GET_SELLERS_ERROR = 'get_sellers_error';
export const GET_SELLERS_TELEMARKETER = 'get_sellers_telemarketer';
export const GET_SELLERS_TELEMARKETER_SUCCESS = 'get_sellers_telemarketer_success';
export const GET_SELLERS_TELEMARKETER_ERROR = 'get_sellers_telemarketer_error';
export const GET_SELLER = 'get_seller';
export const GET_SELLER_SUCCESS = 'get_seller_success';
export const GET_SELLER_ERROR = 'get_seller_error';
export const SEARCH_SELLERS = 'search_sellers';
export const SAVE_SELLER = 'SAVE_SELLER';
export const SAVE_SELLER_SUCCESS = 'SAVE_SELLER_SUCCESS';
export const SAVE_SELLER_ERROR = 'SAVE_SELLER_ERROR';
export const AUTOCOMPLETE_SELLERS = 'autocomplete_sellers';
export const SEARCH_SELLERS_SUCCESS = 'search_sellers_success';
export const AUTOCOMPLETE_SELLERS_SUCCESS = 'autocomplete_sellers_success';
export const CLEAR_AUTOCOMPLETE_SELLERS = 'clear_autocomplete_sellers';
export const CLEAR_AUTOCOMPLETE_SELLERS_TELEMARKETER = 'clear_autocomplete_sellers_telemarketer';


//Common
export const SHOW_LOADER = 'show_loader';
export const HIDE_LOADER = 'hide_loader';
export const SHOW_ERROR = 'show_error';
export const CLEAR_ERROR = 'clear_error';
export const SHOW_MESSAGE = 'show_message';
export const GET_CURRENCY_TYPES = 'get_currency_types';
export const GET_CURRENCY_TYPES_SUCCESS = 'get_currency_types_success';
export const GET_BANKS = 'get_banks';
export const GET_BANKS_SUCCESS = 'get_banks_success';
export const GET_TAX_WITHHOLDINGS_TYPES = 'get_tax_withholdings_types';
export const GET_TAX_WITHHOLDINGS_TYPES_SUCCESS  = ' get_tax_withholdings_types_success';
export const CLEAR_MESSAGE = 'clear_message';
export const TOGGLE_FIX_HEADER = 'toggle_fix_header';
export const SET_HEADER_HEIGHT = 'set_header_height';
export const SET_ACTION_BAR_SHOW_REMOVE = 'set_action_bar_show_remove';
export const INIT_ACTION_BAR = 'init_action_bar';
export const TRIGGER_UPDATE_NOTIFICATION = 'TRIGGER_UPDATE_NOTIFICATION';
export const HIDE_UPDATE_NOTIFICATION = 'HIDE_UPDATE_NOTIFICATION';
export const CHECK_FOR_UPDATES = 'CHECK_FOR_UPDATES'

// Main Groups
export const GET_MAIN_GROUP = 'get_main_group';
export const GET_MAIN_GROUP_ERROR = 'get_main_group_error';
export const GET_MAIN_GROUP_SUCCESS = 'get_main_group_success';
export const SEARCH_MAIN_GROUPS = 'search_main_groups';
export const SEARCH_MAIN_GROUPS_SUCCESS = 'search_main_groups_success';
export const AUTOCOMPLETE_MAIN_GROUPS = 'autocomplete_main_groups';
export const AUTOCOMPLETE_MAIN_GROUPS_SUCCESS = 'autocomplete_main_groups_success';


// Sub Groups
export const GET_SUB_GROUP = 'get_sub_group';
export const GET_SUB_GROUP_BY_CODE = 'get_sub_group_by_code';
export const GET_SUB_GROUP_BY_CODE_SUCCESS = 'get_sub_group_by_code_success';
export const GET_SUB_GROUP_BY_CODE_ERROR = 'get_sub_group_by_code_error';
export const GET_SUB_GROUP_ERROR = 'get_sub_group_error';
export const GET_SUB_GROUP_SUCCESS = 'get_sub_group_success';
export const SEARCH_SUB_GROUPS = 'search_sub_groups';
export const SEARCH_SUB_GROUPS_SUCCESS = 'search_sub_groups_success';
export const AUTOCOMPLETE_SUB_GROUPS = 'autocomplete_sub_groups';
export const AUTOCOMPLETE_SUB_GROUPS_SUCCESS = 'autocomplete_sub_groups_success';

// Groups
export const GET_GROUP = 'get_group';
export const GET_GROUP_ERROR = 'get_group_error';
export const GET_GROUP_SUCCESS = 'get_group_success';
export const SEARCH_GROUPS = 'search_groups';
export const SEARCH_GROUPS_SUCCESS = 'search_groups_success';
export const AUTOCOMPLETE_GROUPS = 'autocomplete_groups';
export const AUTOCOMPLETE_GROUPS_SUCCESS = 'autocomplete_groups_success';


//Segments
export const GET_SEGMENTS = 'get_segments';
export const GET_ACTIVE_SEGMENTS = 'get_active_segments';
export const ADD_UPDATE_SEGMENT = 'add_update_segment';
export const ADD_UPDATE_SEGMENT_SUCCESS = 'add_update_segment_success';
export const ADD_UPDATE_SEGMENT_ERROR = 'add_update_segment_error';
export const GET_SEGMENTS_SUCCESS = 'get_segments_success';
export const GET_SEGMENTS_ERROR = 'get_segments_error';
export const GET_SEGMENT = 'get_segment';
export const GET_SEGMENT_ERROR = 'get_segment_error';
export const GET_SEGMENT_SUCCESS = 'get_segment_success';
export const SEARCH_SEGMENTS = 'search_segments';
export const SEARCH_SEGMENTS_SUCCESS = 'search_segments_success';
export const AUTOCOMPLETE_SEGMENTS = 'autocomplete_segments';
export const AUTOCOMPLETE_SEGMENTS_SUCCESS = 'autocomplete_segments_success';
export const GET_BRANDS_BY_SEGMENTS = 'get_brands_by_segments';
export const GET_BRANDS_BY_SEGMENTS_SUCCESS = 'get_brands_by_segments_success';
export const GET_BRANDS_BY_SEGMENTS_ERROR = 'get_brands_by_segments_error';
export const GET_DEALS_TREE = 'get_deals_tree';
export const GET_DEALS_TREE_SUCCESS = 'get_deals_tree_success';
export const SET_CATALOG_SEGMENT = 'set_catalog_segment';
export const CLEAR_SEGMENTS = 'clear_segments'

//LockMark
export const GET_LOCK_MARK = 'get_lock_mark';
export const GET_LOCK_MARK_SUCCESS = 'get_lock_mark_success';
export const GET_LOCK_MARK_ERROR = 'get_lock_mark_error';
export const ADD_UPDATE_LOCK_MARK = 'add_update_lock_mark';
export const EXPORT_LOCK_MARKS = 'export_lock_marks'
export const ADD_UPDATE_LOCK_MARK_SUCCESS = 'add_update_lock_mark_success';
export const ADD_UPDATE_LOCK_MARK_ERROR = 'add_update_lock_mark_error';
export const SEARCH_LOCK_MARKS = 'search_lock_mark';
export const SEARCH_LOCK_MARKS_SUCCESS = 'search_lock_marks_success';
export const AUTOCOMPLETE_LOCK_MARKS = 'autocomplete_lock_marks_';
export const AUTOCOMPLETE_LOCK_MARKS_SUCCESS = 'autocomplete_lock_marks__success';
export const AUTOCOMPLETE_LOCK_MARKS_ERROR = 'autocomplete_lock_marks__error';
//Brands
export const GET_BRANDS = 'get_brands';
export const ADD_UPDATE_BRAND = 'add_update_brand';
export const ADD_UPDATE_BRAND_SUCCESS = 'add_update_brand_success';
export const ADD_UPDATE_BRAND_ERROR = 'add_update_brand_error';
export const GET_BRANDS_SUCCESS = 'get_brands_success';
export const GET_BRANDS_ERROR = 'get_brands_error';
export const GET_BRAND = 'get_brand';
export const GET_BRAND_ERROR = 'get_brand_error';
export const GET_BRAND_SUCCESS = 'get_brand_success';
export const SEARCH_BRANDS = 'search_brands';
export const SEARCH_BRANDS_SUCCESS = 'search_brands_success';
export const AUTOCOMPLETE_BRANDS = 'autocomplete_brands';
export const AUTOCOMPLETE_BRANDS_SUCCESS = 'autocomplete_brands_success';
export const AUTOCOMPLETE_BRANDS_ERROR = 'autocomplete_brands_error';

//Catalogs
export const DOWNLOAD_PRICES_LIST = 'download_prices_list';
export const SET_SHOW_PRICES_LIST_MODAL = 'set_show_prices_list_modal';
export const DOWNLOAD_PRICES_LIST_SUCCESS = 'download_prices_list_success';
export const DOWNLOAD_PRICES_LIST_ERROR = 'download_prices_list_error';
export const GET_CATALOGS = 'get_catalogs';
export const ADD_UPDATE_CATALOG = 'add_update_catalog';
export const ADD_UPDATE_CATALOG_SUCCESS = 'add_update_catalog_success';
export const ADD_UPDATE_CATALOG_ERROR = 'add_update_catalog_error';
export const GET_CATALOGS_SUCCESS = 'get_catalogs_success';
export const GET_CATALOGS_ERROR = 'get_catalogs_error';
export const GET_CATALOG = 'get_catalog';
export const GET_CATALOG_ERROR = 'get_catalog_error';
export const GET_CATALOG_SUCCESS = 'get_catalog_success';
export const SEARCH_CATALOGS = 'search_catalog';
export const SEARCH_CATALOGS_SUCCESS = 'search_catalog_success';
export const AUTOCOMPLETE_CATALOGS = 'autocomplete_catalogs';
export const AUTOCOMPLETE_CATALOGS_SUCCESS = 'autocomplete_catalogs_success';
export const SET_FILTER_SELECTED = 'set_filter_selected';
export const GET_LINEAS_CATALOGOS = 'GET_LINEAS_CATALOGOS';
export const GET_LINEAS_CATALOGOS_SUCCESS = 'GET_LINEAS_CATALOGOS_SUCCESS';
export const TOGGLE_FILTER_BY_STOCK = 'TOGGLE_FILTER_BY_STOCK';
export const CLEAR_SEARCH_PRODUCT = 'clear_search_product';

// Catalog Entry
export const GET_CATALOGS_ENTRIES = 'get_catalogs_entries';
export const ADD_UPDATE_CATALOG_ENTRY = 'add_update_catalog_entry';
export const ADD_UPDATE_CATALOG_ENTRY_SUCCESS = 'add_update_catalog_entry_success';
export const ADD_UPDATE_CATALOG_ENTRY_ERROR = 'add_update_catalog_entry_error';
export const GET_CATALOGS_ENTRIES_SUCCESS = 'get_catalogs_ENTRIES_success';
export const GET_CATALOGS_ENTRIES_ERROR = 'get_catalogs_entries_error';
export const GET_CATALOG_ENTRY = 'get_catalog_entry';
export const GET_CATALOG_ENTRY_ERROR = 'get_catalog_entry_error';
export const GET_CATALOG_ENTRY_SUCCESS = 'get_catalog_entry_success';
export const SEARCH_CATALOGS_ENTRIES = 'search_catalog';
export const SEARCH_CATALOGS_ENTRIES_SUCCESS = 'search_catalog_success';
export const AUTOCOMPLETE_CATALOGS_ENTRIES = 'autocomplete_catalogs_entries';
export const AUTOCOMPLETE_CATALOGS_ENTRIES_SUCCESS = 'autocomplete_catalogs_entries_success';

//Catalog
export const SET_CATALOG_VIEW = 'set_catalog_view';
export const SET_SHOW_CATEGORIES = 'set_show_categories';
export const SET_SHOW_QUICK_CART = 'set_show_quick_cart';
export const SET_SHOW_ESTIMATE = 'set_show_estimate';
export const SET_SELECTED_CATALOG_LINE = 'set_selected_catalog_line';
export const SHOW_ITEM_DETAIL = 'show_item_detail';
export const HIDE_ITEM_DETAIL = 'hide_item_detail';

//Orders
export const ORDER_REQUEST = 'order_request';
export const GET_CURRENT_ORDER = 'get_current_order';
export const GET_CURRENT_ORDER_SUCCESS = 'get_current_order_success';
export const GET_CURRENT_ORDER_ERROR = 'get_current_order_error';
export const ADD_ITEM_TO_ORDER = 'add_item_to_order';
export const ADD_ITEM_TO_ORDER_SUCCESS = 'add_item_to_order_success';
export const ADD_ITEM_TO_ORDER_ERROR = 'add_item_to_order_error';
export const REMOVE_ITEM_FROM_ORDER = 'remove_item_from_order';
export const REMOVE_ITEM_FROM_ORDER_SUCCESS = 'remove_item_from_order_success';
export const REMOVE_ITEM_FROM_ORDER_ERROR = 'remove_item_from_order_error';
export const CHANGE_ITEM_QUANTITY_FROM_ORDER = 'change_item_quantity_from_order';
export const CHANGE_ITEM_QUANTITY_FROM_ORDER_SUCCESS = 'change_item_quantity_from_order_success';
export const CHANGE_ITEM_QUANTITY_FROM_ORDER_ERROR = 'change_item_quantity_from_order_error';
export const SET_DATA_TO_ORDER = 'set_data_to_order';
export const SET_DATA_TO_ORDER_SUCCESS = 'set_data_to_order_success';
export const SET_DATA_TO_ORDER_ERROR = 'set_data_to_order_error';
export const SAVE_ORDER = 'save_order';
export const SAVE_ORDER_SUCCESS = 'save_order_success';
export const SAVE_ORDER_ERROR = 'save_order_error';
export const CANCEL_ORDER = 'cancel_order';
export const CANCEL_ORDER_SUCCESS = 'cancel_order_success';
export const CANCEL_ORDER_ERROR = 'cancel_order_error';
export const CONFIRM_ORDER = 'confirm_order';
export const CONFIRM_ORDER_AS_ADMIN = 'confirm_order_as_admin';
export const CONFIRM_ORDER_SUCCESS = 'confirm_order_success';
export const CONFIRM_ORDER_ERROR = 'confirm_order_error';
export const CONFIRM_ORDER_DIALOG_CLOSED = 'confirm_order_dialog_closed';
export const ADD_CLIENT_TO_ORDER = 'add_client_to_order';
export const ADD_CLIENT_TO_ORDER_SUCCESS = 'add_client_to_order_success';
export const ADD_CLIENT_TO_ORDER_ERROR = 'add_client_to_order_error';
export const ADD_ITEM_TO_ORDER_FROM_CODE = 'add_item_to_order_from_code';
export const ADD_ITEM_TO_ORDER_FROM_CODE_SUCCESS = 'add_item_to_order_from_code_success';
export const ADD_ITEM_TO_ORDER_FROM_CODE_ERROR = 'add_item_to_order_from_code_error';
export const ADD_TRANSPORT_TO_ORDER = 'add_transport_to_order';
export const ADD_TRANSPORT_TO_ORDER_SUCCESS = 'add_transport_to_order_success';
export const ADD_TRANSPORT_TO_ORDER_ERROR = 'add_transport_to_order_error';
export const APPLY_DISCOUNTS_TO_PRODUCTS = 'apply_discounts_to_products';
export const APPLY_DISCOUNTS_TO_PRODUCTS_SUCCESS = 'apply_discounts_to_products_success';
export const APPLY_DISCOUNTS_TO_PRODUCTS_LOADING = 'apply_discounts_to_products_loading';
export const APPLY_DISCOUNTS_TO_PRODUCTS_ERROR = 'apply_discounts_to_products_error';
export const GET_ORDER_BY_USER_SUCCESS = 'get_order_by_user_success';
export const GET_ORDERS_BY_DATE_SUCCESS = 'get_orders_by_date_success';
export const GET_ORDER_BY_USER = 'get_order_by_user';
export const GET_ORDERS_BY_USER_PENDING = 'get_orders_by_user_pending';
export const GET_ORDERS_BY_DATE = 'get_orders_by_date';
export const GET_ORDERS_BY_DATE_PENDING = 'get_orders_by_date_pending';
export const CLEAN_ORDERS_BY_DATE = 'clean_orders_by_date';
export const GET_ORDER_BY_ID_SUCCESS = 'get_order_by_id_success';
export const GET_ORDER_BY_ID = 'get_order_by_id';
export const ADD_PRODUCTS_TO_CURRENT = 'ADD_PRODUCTS_TO_CURRENT';
export const ADD_PRODUCTS_TO_CURRENT_SUCCESS = 'ADD_PRODUCTS_TO_CURRENT_SUCCESS';
export const CHANGE_CURRENT_ORDER = 'CHANGE_CURRENT_ORDER';
export const CHANGE_CURRENT_ORDER_SUCCESS = 'CHANGE_CURRENT_ORDER_SUCCESS';
export const CLEAN_ORDER = 'CLEAN_ORDER';
export const GET_REFUND_CODES = 'GET_REFUND_CODES';
export const GET_REFUND_CODES_SUCCESS = 'GET_REFUND_CODES_SUCCESS';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const CREATE_CREDIT_NOTE = "CREATE_CREDIT_NOTE";
export const CREATE_CREDIT_NOTE_SUCCESS = "CREATE_CREDIT_NOTE_SUCCESS";
export const GET_CN_TYPES = 'GET_CN_TYPES';
export const GET_CN_TYPES_SUCCESS = 'GET_CN_TYPES_SUCCESS';
export const SET_PARAMS_SNC = 'SET_PARAMS_SNC';

// Receipt
export const UPLOAD_PROVISIONAL_RECEIPT = "UPLOAD_PROVISIONAL_RECEIPT";
export const UPLOAD_PROVISIONAL_RECEIPT_SUCCESS = "UPLOAD_PROVISIONAL_RECEIPT_SUCCESS";
export const GET_PROVISIONAL_RECEIPTS = "get_provisional_receipts";
export const GET_PROVISIONAL_RECEIPTS_SUCCESS = "GET_PROVISIONAL_RECEIPTS_SUCCESS";
export const SUBMIT_RECEIPTS = "SUBMIT_RECEIPTS";
export const SUBMIT_RECEIPTS_SUCCESS = "SUBMIT_RECEIPTS_SUCCESS";
export const RESET_SUBMIT_RECEIPTS_SUCCESS = "RESET_SUBMIT_RECEIPTS_SUCCESS";

// Quotation
export const GET_CURRENT_QUOTATION = 'get_current_quotation';
export const GET_CURRENT_QUOTATION_SUCCESS = 'get_current_quotation_success';
export const GET_CURRENT_QUOTATION_ERROR = 'get_current_quotation_error';
export const ADD_ITEM_QUOTATION = 'add_item_quotation';
export const ADD_ITEM_QUOTATION_SUCCESS = 'add_item_quotation_success';
export const ADD_ITEM_QUOTATION_ERROR = 'add_item_quotation_error';
export const ADD_ITEM_QUOTATION_FROM_CODE = 'add_item_quotation_from_code';
export const ADD_ITEM_QUOTATION_FROM_CODE_SUCCESS = 'add_item_quotation_from_code_success';
export const ADD_ITEM_QUOTATION_FROM_CODE_ERROR = 'add_item_quotation_from_code_error';
export const CHANGE_ITEM_QUANTITY_QUOTATION = 'change_item_quantity_quotation';
export const CHANGE_ITEM_QUANTITY_QUOTATION_SUCCESS = 'change_item_quantity_quotation_success';
export const CHANGE_ITEM_QUANTITY_QUOTATION_ERROR = 'change_item_quantity_quotation_error';
export const REMOVE_ITEM_QUOTATION = 'remove_item_quotation';
export const REMOVE_ITEM_QUOTATION_SUCCESS = 'remove_item_quotation_success';
export const REMOVE_ITEM_QUOTATION_ERROR = 'remove_item_quotation_error';
export const SET_DATA_QUOTATION = 'set_data_quotation';
export const SET_DATA_QUOTATION_SUCCESS = 'set_data_quotation_success';
export const SET_DATA_QUOTATION_ERROR = 'set_data_quotation_error';
export const SAVE_QUOTATION = 'save_quotation';
export const SAVE_QUOTATION_SUCCESS = 'save_quotation_success';
export const SAVE_QUOTATION_ERROR = 'save_quotation_error';
export const CANCEL_QUOTATION = 'cancel_quotation';
export const CANCEL_QUOTATION_SUCCESS = 'cancel_quotation_success';
export const CANCEL_QUOTATION_ERROR = 'cancel_quotation_error';
export const NEW_QUOTATION = 'new_quotation';
export const NEW_QUOTATION_SUCCESS = 'new_quotation_success';
export const NEW_QUOTATION_ERROR = 'new_quotation_error';
export const GET_QUOTATIONS_BY_USER = 'get_quotations_by_user';
export const GET_QUOTATIONS_BY_USER_SUCCESS = 'get_quotations_by_user_success';
export const GET_QUOTATIONS_BY_USER_ERROR = 'get_quotations_by_user_error';
export const CHANGE_CURRENT_QUOTATION = 'change_current_quotation';
export const GET_QUOTATION_BY_ID = 'get_quotation_by_id';
export const GET_QUOTATION_BY_ID_SUCCESS = 'get_quotation_by_id_success';
export const GET_QUOTATION_BY_ID_ERROR = 'get_quotation_by_id_error';
export const ADD_PRODUCTS_CURRENT_QUOTATION = 'ADD_PRODUCTS_CURRENT_QUOTATION';
export const ADD_PRODUCTS_CURRENT_QUOTATION_SUCCESS = 'ADD_PRODUCTS_CURRENT_QUOTATION_SUCCESS';

//CurrentAccount
export const GET_CURRENT_ACCOUNT = 'get_current_account';
export const GET_CURRENT_ACCOUNT_SUCCESS = 'get_current_account_success';
export const DOWNLOAD_ACCOUNTING_DOCUMENT = 'download_accounting_document';
export const DOWNLOAD_COMPENSATED_MATCHES = 'download_compensated_matches';

//Statistics
export const GET_STATISTICS = 'get_statistics';
export const GET_STATISTICS_SUCCESS = 'get_statistics_success';

//Products
export const GET_PRODUCTS = 'get_products';
export const GET_PRODUCT = 'get_product';
export const GET_PRODUCT_SUCCESS = 'get_product_success';
export const GET_PRODUCTS_ERROR = 'get_products_error';
export const GET_PRODUCTS_SUCCESS = 'get_products_success';
export const SEARCH_PRODUCTS = 'search_product';
export const SEARCH_PRODUCTS_SUCCESS = 'search_product_success';
export const SEARCH_CATALOG_PRODUCTS = 'search_catalog_products';
export const SEARCH_CATALOG_PRODUCTS_SUCCESS = 'search_catalog_products_success';
export const SEARCH_CATALOG_PRODUCTS_ERROR = 'search_catalog_products_error';
export const SORT_CHANGE = 'sort_change';
export const AUTOCOMPLETE_PRODUCTS = 'autocomplete_products';
export const AUTOCOMPLETE_PRODUCTS_SUCCESS = 'autocomplete_products_success';
export const GET_PRODUCTS_BY_LINEA_CATALOGO = 'get_products_by_linea_catalogo';
export const GET_PRODUCTS_BY_LINEA_CATALOGO_SUCCESS = 'get_products_by_linea_catalogo_success';
export const GET_PRODUCTS_BY_LINEA_CATALOGO_ERROR = 'get_products_by_linea_catalogo_error';
export const GET_PRODUCTS_BY_VENDOR_ID = 'get_products_by_vendor_id';
export const GET_PRODUCTS_BY_VENDOR_ID_SUCCESS = 'get_products_by_vendor_id_success';
export const GET_PRODUCTS_BY_VENDOR_ID_ERROR = 'get_products_by_vendor_id_error';
export const GET_DEAL_PRODUCT_ID = 'get_deal_product_id';
export const GET_DEAL_PRODUCT_ID_SUCCESS = 'get_deal_product_id_success';
export const GET_PRODUCT_BY_CODE = 'get_product_by_code';
export const GET_PRODUCT_BY_CODE_SUCCESS = 'get_product_by_code_success';
export const GET_PRODUCTS_BY_IDS = 'get_products_by_ids';
export const GET_PRODUCTS_BY_IDS_SUCCESS = 'get_products_by_ids_success';
export const GET_PRODUCT_PRICE_CLIENT = 'GET_PRODUCT_PRICE_CLIENT';
export const GET_PRODUCT_PRICE_CLIENT_SUCCESS = 'GET_PRODUCT_PRICE_CLIENT_SUCCESS';
export const GET_PRODUCT_PRICE_CLIENT_ERROR = 'GET_PRODUCT_PRICE_CLIENT_ERROR';
export const GET_PRODUCT_PRICE_BY_CLIENT_LOADING = 'GET_PRODUCT_PRICE_BY_CLIENT_LOADING';
export const SHOW_ONE_PRODUCT = 'show_one_product';
export const SHOW_ONE_PRODUCT_SUCCESS = 'show_one_product_success';
export const GET_APPLICATIONS_BY_PRODUCT_CODE = 'get_applications_by_product_code';
export const GET_APPLICATION_BY_PRODUCT_CODE_LOADING = 'get_application_by_product_code_loading';
export const GET_APPLICATIONS_BY_PRODUCT_CODE_SUCCESS = 'get_applications_by_product_code_success';
export const GET_APPLICATIONS_BY_PRODUCT_ID = 'get_applications_by_product_id';
export const GET_APPLICATIONS_BY_PRODUCT_ID_SUCCESS = 'get_applications_by_product_id_success';
export const DELETE_APPLICATION_FROM_PRODUCT = 'delete_application_from_product';
export const DELETE_APPLICATION_FROM_PRODUCT_SUCCESS = 'delete_application_from_product_success';
export const CREATE_APPLICATION_FROM_PRODUCT = 'create_application_from_product';
export const CREATE_APPLICATION_FROM_PRODUCT_SUCCESS = 'create_application_from_product_success'
export const EDIT_APPLICATION_FROM_PRODUCT = 'edit_application_from_product';
export const EDIT_APPLICATION_FROM_PRODUCT_SUCCESS = 'edit_application_from_product_success';
export const RESET_SUCCESS = 'reset_success';


//Deals
export const ADD_DEAL = 'add_deal';
export const ADD_DEAL_SUCCESS = 'add_deal_success';
export const UPDATE_DEAL = 'update_deal';
export const UPDATE_DEAL_SUCCESS = 'update_deal_success';
export const ADD_DEAL_ERROR = 'add_deal_error';
export const UPDATE_DEAL_ERROR = 'update_deal_error';
export const SEARCH_DEALS = 'search_deals';
export const SEARCH_DEALS_SUCCESS   = 'search_deals_success';
export const AUTOCOMPLETE_DEALS = 'autocomplete_deals';
export const AUTOCOMPLETE_DEALS_SUCCESS = 'autocomplete_deals_success';
export const GET_DEAL = 'get_deal';
export const GET_DEAL_SUCCESS = 'get_deal_success';
export const REMOVE_DEAL = 'remove_deal';
export const REMOVE_DEAL_SUCCESS = 'remove_deal_success';
export const GET_DEALS_SEGMENT = 'get_deals_segment';
export const GET_DEALS_SEGMENT_SUCCESS = 'get_deals_segment_success';
export const INIT_DEAL_COMPONENT = 'init_deal_component';
export const SET_SELECTED_DEALS_BRAND = 'set_selected_deals_brand';
export const SET_SELECTED_DEALS_SEGMENT = 'set_selected_deals_segment';
export const CLEAR_SELECTED_DEALS_BRAND = 'clear_selected_deals_brand';
export const GET_DEALS_BY_BRAND = 'get_deals_by_brand';
export const GET_DEALS_BY_BRAND_SUCCESS = 'get_deals_by_brand_success';
export const SHOW_DEAL_MODAL = 'show_deal_modal';
export const HIDE_DEAL_MODAL = 'hide_deal_modal';


//Product
export const GET_PRODUCT_ERROR = 'get_product_error';
export const UPDATE_PRODUCT_IMAGES = 'update_product_images';
export const UPDATE_PRODUCT_SUCCESS = 'update_product_success';
export const UPDATE_PRODUCT_ERROR = 'update_product_error';
export const SELECT_MAIN_PICTURE = 'select_main_picture';
export const UPDATE_PRODUCT = 'update_product';


//Configurate Client
export const CONFIGURATE_CLIENT_UPDATE = 'configurate_client_update';
export const CONFIGURATE_CLIENT_UPDATE_SUCCESS = 'configurate_client_update_success';
export const GET_CONFIG_CLIENT = 'get_config_client';
export const GET_CONFIG_CLIENT_SUCCESS = 'get_config_client_success';
export const GET_LOGO_IMAGE = 'GET_LOGO_IMAGE';
export const GET_LOGO_IMAGE_SUCCESS = 'GET_LOGO_IMAGE_SUCCESS';

//Banners
export const SEARCH_BANNERS = 'SEARCH_BANNERS';
export const SEARCH_BANNERS_SUCCESS = 'SEARCH_BANNERS_SUCCESS';
export const AUTOCOMPLETE_BANNERS = 'AUTOCOMPLETE_BANNERS';
export const AUTOCOMPLETE_BANNERS_SUCCESS = 'AUTOCOMPLETE_BANNERS_SUCCESS';
export const ADD_BANNER = 'ADD_BANNER';
export const ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS';
export const GET_BANNER = 'GET_BANNER';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const UPDATE_BANNER = 'UPDATE_BANNER';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const SEARCH_BANNERS_BY_SEGMENT = 'SEARCH_BANNERS_BY_SEGMENT';
export const SEARCH_BANNERS_BY_SEGMENT_SUCCESS = 'SEARCH_BANNERS_BY_SEGMENT_SUCCESS';

//Offline
export const GO_OFFLINE = 'go_offline';
export const GO_ONLINE = 'go_online';
export const SHOW_OFFLINE_MERGE = 'show_offline_merge';
export const SET_MERGE_ORDERS = 'set_merge_orders';
export const GET_ONLINE_ORDER_TO_MERGE = 'GET_ONLINE_ORDER_TO_MERGE';
export const GET_ONLINE_ORDER_TO_MERGE_SUCCESS = 'GET_ONLINE_ORDER_TO_MERGE_SUCCESS';
export const GET_ONLINE_QUOTATION_TO_MERGE = 'GET_ONLINE_QUOTATION_TO_MERGE';
export const GET_ONLINE_QUOTATION_TO_MERGE_SUCCESS = 'GET_ONLINE_QUOTATION_TO_MERGE_SUCCESS';

//Categories
export const SEARCH_CATEGORIES = 'SEARCH_CATEGORIES';
export const SEARCH_CATEGORIES_SUCCESS = 'SEARCH_CATEGORIES_SUCCESS';
export const AUTOCOMPLETE_CATEGORIES = 'AUTOCOMPLETE_CATEGORIES';
export const AUTOCOMPLETE_CATEGORIES_SUCCESS = 'AUTOCOMPLETE_CATEGORIES_SUCCESS';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_BRANDS = 'GET_CATEGORY_BRANDS';
export const GET_CATEGORY_BRANDS_SUCCESS = 'GET_CATEGORY_BRANDS_SUCCESS';
export const GET_CATEGORY_MODELS = 'GET_CATEGORY_MODELS';
export const GET_CATEGORY_MODELS_SUCCESS = 'GET_CATEGORY_MODELS_SUCCESS';
export const GET_CATEGORY_ALL_CHARACTERISTICS = "GET_CATEGORY_ALL_CHARACTERISTICS";
export const GET_CATEGORY_ALL_CHARACTERISTICS_SUCCESS = "GET_CATEGORY_ALL_CHARACTERISTICS_SUCCESS";
export const SAVE_CATEGORY = 'SAVE_CATEGORY';
export const SAVE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const CLEAR_CATEGORY_SUCCESS = 'CLEAR_CATEGORY_SUCCESS';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';

//Connectivity
export const CHECK_CONNECTION = "check_connection";
export const ONLINE = "online";
export const OFFLINE = "offline";

//Appointment
export const GET_APPOINTMENT = 'get_appointment';
export const GET_APPOINTMENT_ERROR = 'get_appointment_error';
export const GET_APPOINTMENT_SUCCESS = 'get_appointment_success';
export const GET_APPOINTMENTS = 'get_appointments';
export const GET_APPOINTMENTS_ERROR = 'get_appointments_error';
export const GET_APPOINTMENTS_SUCCESS = 'get_appointments_success';
export const GET_APPOINTMENT_STATES = 'get_appointment_states';
export const GET_APPOINTMENT_STATES_SUCCESS = 'get_appointment_states_success';
export const GET_APPOINTMENT_STATES_ERROR = 'get_appointment_states_error';
export const GET_APPOINTMENT_ACTIONS = 'get_appointment_actions';
export const GET_APPOINTMENT_ACTIONS_SUCCESS = 'get_appointment_actions_success';
export const GET_APPOINTMENT_ACTIONS_ERROR = 'get_appointment_actions_error';
export const SAVE_APPOINTMENT = 'save_appointment';
export const SAVE_APPOINTMENT_ERROR = 'save_appointment_error';
export const SAVE_APPOINTMENT_SUCCESS = 'save_appointment_success';
export const UPDATE_APPOINTMENT = 'update_appointment';
export const UPDATE_APPOINTMENT_SUCCESS = 'update_appointment_error';
export const UPDATE_APPOINTMENT_ERROR = 'update_appointment_success';
export const SELECT_APPOINTMENT_DATE = 'select_appointment_date';
export const SELECT_APPOINTMENT_CLIENT = 'select_appointment_client';
export const SELECT_APPOINTMENT_SELLER = 'select_appointment_seller';
export const GET_MY_APPOINTMENTS = 'get_my_appointments';
export const GET_MY_APPOINTMENTS_ERROR = 'get_my_appointments_error';
export const GET_MY_APPOINTMENTS_SUCCESS = 'get_my_appointments_success';
export const GET_MY_APPOINTMENTS_GROUP = 'get_my_appointments_group';
export const GET_MY_APPOINTMENTS_GROUP_ERROR= 'get_my_appointments_group_error';
export const GET_MY_APPOINTMENTS_GROUP_SUCCESS = 'get_my_appointments_group_success';
export const OPEN_APPOINTMENT_MODAL = 'open_appointment_modal';
export const CLOSE_APPOINTMENT_MODAL = 'close_appointment_modal';
export const OPEN_EXPORT_MODAL = 'open_export_modal';
export const CLOSE_EXPORT_MODAL = 'close_export_modal';
export const DELETE_APPOINTMENT = 'delete_appointment';
export const DELETE_APPOINTMENT_SUCCESS = 'delete_appointment_error';
export const DELETE_APPOINTMENT_ERROR = 'delete_appointment_success';
export const CLEAN_APPOINTMENT = 'clean_appointment';
export const SET_APPOINTMENT_TIME = 'set_appointment_time';
export const EXCEL_EXPORT_APPOINTMENT = 'excel_export_appointment';
export const GET_APPOINTMENT_EVENTS = 'get_appointment_events';
export const GET_APPOINTMENT_EVENTS_SUCCESS = 'get_appointment_events_success';
export const GET_APPOINTMENT_EVENTS_ERROR = 'get_appointment_events_error';
export const GET_ACTIONS_GROUPED_BY_SELLER = 'get_actions_grouped_by_seller';
export const GET_ACTIONS_GROUPED_BY_SELLER_SUCCESS = 'get_actions_grouped_by_seller_success';
export const GET_AMOUNT_OF_ACTIONS_BY_SELLER = "GET_AMOUNT_OF_ACTION_BY_SELLER";
export const GET_AMOUNT_OF_ACTIONS_BY_SELLER_SUCCESS = "GET_AMOUNT_OF_ACTION_BY_SELLER_SUCCESS";

//Dashboard
export const GET_CHARTS_INFO = "get_charts_info";
export const GET_CHARTS_INFO_SUCCESS = "get_charts_info_success";
export const GET_CHARTS_INFO_ERROR = "get_charts_info_error";
export const GET_DETAIL_SELLE_INFO = "get_detail_selle_info";
export const GET_DETAIL_SELLE_INFO_SUCCESS = "get_detail_selle_info_success";
export const GET_DETAIL_SELLE_INFO_ERROR = "get_detail_selle_info_error";

// AccountForm
export const GET_ACCOUNT_FORM = 'get_account_form';
export const GET_ACCOUNT_FORM_SUCCESS = 'get_account_form_success';
export const GET_ACCOUNT_FORM_ERROR = 'get_account_form_error';
export const SEARCH_ACCOUNT_FORM = 'search_account_form';
export const SEARCH_ACCOUNT_FORM_SUCCESS = 'search_account_form_success';
export const SEARCH_ACCOUNT_FORM_ERROR = 'search_account_form_error';
export const DELETE_ACCOUNT_FORM = 'delete_account_form';
export const CREATE_ACCOUNT_FORM = 'create_account_form';
export const CREATE_ACCOUNT_FORM_SUCCESS = 'create_account_form_success';
export const CREATE_ACCOUNT_FORM_ERROR = 'create_account_form_error';
export const EDIT_ACCOUNT_FORM = 'edit_account_form';
export const EDIT_ACCOUNT_FORM_SUCCESS = 'edit_account_form_success';
export const EDIT_ACCOUNT_FORM_ERROR = 'edit_account_form_error';
export const DELETE_ACCOUNT_FORM_SUCCESS = 'delete_account_form_success';
export const DELETE_ACCOUNT_FORM_ERROR = 'delete_account_form_error';
export const COMPLETE_ACCOUNT_FORM = "complete_account_form";
export const COMPLETE_ACCOUNT_FORM_SUCCESS = "complete_account_form_success";
export const COMPLETE_ACCOUNT_FORM_ERROR = "complete_account_form_error";

export const GET_WORKS = "get_works";
export const GET_WORKS_SUCCESS = "get_works_success";
export const GET_WORKS_ERROR = "get_works_error";
export const GET_SEGMENTS_WITH_BRANDS = "get_segments_with_brands"
export const GET_SEGMENTS_WITH_BRANDS_SUCCESS = "get_segments_with_brands_success"
export const GET_SEGMENTS_WITH_BRANDS_ERROR = "get_segments_with_brands_error"
export const CLEAR_ACCOUNT_FORM_STATE = "clear_account_form_state"
export const GET_LINK_FOR_DETAIL = "get_link_for_detail";
export const GET_LINK_FOR_DETAIL_SUCCESS = "get_link_for_detail_success";
export const GET_LINK_FOR_DETAIL_ERROR = "get_link_for_detail_error";
export const CLEAR_LINK_STATE = "clear_link_state";

//UserConfig
export const GET_USER_CONFIGURATIONS = 'get_user_configurations';
export const GET_USER_CONFIGURATIONS_SUCCESS = 'get_user_configurations_success';
export const GET_USER_CONFIGURATIONS_ERROR = 'get_user_configurations_error';
export const SET_USER_CONFIGURATIONS = 'set_user_configurations';
export const SET_USER_CONFIGURATIONS_SUCCESS = 'set_user_configurations_success';
export const SET_USER_CONFIGURATIONS_ERROR = 'set_user_configurations_error';
export const ADD_PRODUCT_TO_FAVORITES = "add_product_to_favorites"
export const ADD_PRODUCT_TO_FAVORITES_SUCCESS = "add_product_to_favorites_success"
export const ADD_PRODUCT_TO_FAVORITES_ERROR = "add_product_to_favorites_error"
export const REMOVE_PRODUCT_TO_FAVORITES = "remove_product_to_favorites"
export const REMOVE_PRODUCT_TO_FAVORITES_SUCCESS = "remove_product_to_favorites_success"
export const REMOVE_PRODUCT_TO_FAVORITES_ERROR = "remove_product_to_favorites_error"
export const ADD_OBSERVATION_TO_FAVORITE = "add_observation_to_favorite"
export const ADD_OBSERVATION_TO_FAVORITE_SUCCESS = "add_observation_to_favorite_success"
export const ADD_OBSERVATION_TO_FAVORITE_ERROR = "add_observation_to_favorite_error"
export const GET_MY_FAVORITE_PRODUCTS = 'get_my_favorite_products';
export const GET_NEWS_FOR_FAVORITES = 'get_news_for_favorites';
export const GET_NEWS_FOR_FAVORITES_SUCCESS = 'get_news_for_favorites_success';
export const TOGGLE_SHOW_BADGE = 'toggle_show_badge';
export const GET_MY_FAVORITE_PRODUCTS_SUCCESS = 'get_my_favorite_products_success';
export const GET_MY_FAVORITE_PRODUCTS_ERROR = 'get_my_favorite_products_error';

// TODO: Revisar si esto realmente se tiene que borrar según revision 749bebd75
//Characteristics
export const SEARCH_CHARACTERISTICS = 'SEARCH_CHARACTERISTICS';
export const SEARCH_CHARACTERISTICS_SUCCESS = 'SEARCH_CHARACTERISTICS_SUCCESS';
export const AUTOCOMPLETE_CHARACTERISTICS = 'AUTOCOMPLETE_CHARACTERISTICS';
export const AUTOCOMPLETE_CHARACTERISTICS_SUCCESS = 'AUTOCOMPLETE_CHARACTERISTICS_SUCCESS';
export const GET_CHARACTERISTIC = 'GET_CHARACTERISTIC';
export const GET_CHARACTERISTIC_SUCCESS = 'GET_CHARACTERISTIC_SUCCESS';
export const SAVE_CHARACTERISTIC = 'SAVE_CHARACTERISTIC';
export const SAVE_CHARACTERISTIC_SUCCESS = 'SAVE_CHARACTERISTIC_SUCCESS';
export const UPDATE_CHARACTERISTIC = 'UPDATE_CHARACTERISTIC';
export const UPDATE_CHARACTERISTIC_SUCCESS = 'UPDATE_CHARACTERISTIC_SUCCESS';
export const CLEAR_CHARACTERISTIC_SUCCESS = 'CLEAR_CHARACTERISTIC_SUCCESS';

// TODO: Revisar si esto realmente se tiene que borrar según revision 749bebd75
// Applications
export const SAVE_FILE_APPLICATIONS = 'SAVE_FILE_APPLICATIONS';
export const SAVE_FILE_APPLICATIONS_SUCCESS = 'SAVE_FILE_APPLICATIONS_SUCCESS';
export const SAVE_FILE_APPLICATIONS_ERROR = 'SAVE_FILE_APPLICATIONS_ERROR';
export const EXPORT_APPLICATIONS = 'EXPORT_APPLICATIONS';
export const EXPORT_APPLICATIONS_SUCCESS = 'EXPORT_APPLICATIONS_SUCCESS';
export const EXPORT_APPLICATIONS_ERROR = 'EXPORT_APPLICATIONS_ERROR';

// People
export const SEARCH_PERSONS = "search_persons";
export const SEARCH_PERSONS_SUCCESS = "search_persons_success";
export const SEARCH_PERSONS_ERROR = "search_persons_error";
export const AUTOCOMPLETE_PERSONS = "autocomplete_persons";
export const AUTOCOMPLETE_PERSONS_SUCCESS = "AUTOCOMPLETE_PERSONS_success";
export const AUTOCOMPLETE_PERSONS_ERROR = "AUTOCOMPLETE_PERSONS_error";
export const GET_PERSONS = "get_persons";
export const GET_PERSONS_SUCCESS = "get_persons_success";
export const GET_PERSONS_ERROR = "get_persons_error";
export const GET_PERSON = "get_person";
export const GET_PERSON_SUCCESS = "get_person_success";
export const GET_PERSON_ERROR = "get_person_error";
export const CREATE_PERSON = "create_person";
export const CREATE_PERSON_SUCCESS = "create_person_success";
export const CREATE_PERSON_ERROR = "create_person_error";
export const EDIT_PERSON = "edit_person";
export const EDIT_PERSON_SUCCESS = "edit_person_success";
export const EDIT_PERSON_ERROR = "edit_person_error";
export const DELETE_PERSON = "delete_person";
export const DELETE_PERSON_SUCCESS = "delete_person_success";
export const DELETE_PERSON_ERROR = "delete_person_error";
export const CLEAR_PERSONS_STATE = "clear_persons_state"

